import React from 'react';
import Styles from './Disclaimer.styles.js';
import createClassList from '../../../../util/createClassList';
import InView from '../../../Shared/InView/InView';
import sa from '../../../../styles/Components/SimpleAnimation';
import ReactMarkdown from 'react-markdown';
import { loadModel, getColors } from 'vmfcom-lib/utils';
import theme from '../../../../styles/theme';

const Disclaimer = ({ $data = {}, className = '' }) => {
  const primaryClass = 'disclaimer';
  const content = loadModel($data, 'VMFCom', 'Disclaimer');

  return (
    <InView>
      <Styles
        className={createClassList(primaryClass, className)}
        backgroundColor={
          getColors(content?.backgroundColor).primary || theme.colors.white
        }
        textColor={
          getColors(content?.disclaimerTextColor).primary || theme.colors.white
        }
        $data={content}
      >
        {content?.disclaimerText && (
          <sa.div className='inner' slideDirection='up'>
            <ReactMarkdown source={content?.disclaimerText} />
          </sa.div>
        )}
      </Styles>
    </InView>
  );
};

export default Disclaimer;
