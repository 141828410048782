import styled, { css } from 'styled-components'
import { getColors } from 'vmfcom-lib/utils'

const xPosIs = (pos) => ({ $data: { sectionMediaPosition }}) => {
  if (pos === "right") {
    return ["Top Right", "Middle Right", "Bottom Right"].includes(sectionMediaPosition)
  } else if (pos === "left") {
    return ["Top Left", "Middle Left", "Bottom Left"].includes(sectionMediaPosition)
  }

  return false;
}

const mediaPositions = {
  "Top Left": css`
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
  `,
  "Middle Left": css`
    flex-direction: row;
    justify-content: center;
    align-items: center;
  `,
  "Bottom Left": css`
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
  `,
  "Center": css`
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `,
  "Top Right": css`
    flex-direction: row-reverse;
    justify-content: center;
    align-items: flex-start;
  `,
  "Middle Right": css`
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
  `,
  "Bottom Right": css`
    flex-direction: row-reverse;
    justify-content: center;
    align-items: flex-end;
  `
}

const mediaSizes = {
  "Full": css``,
  "Inset": css``,
  "Background Full": css``,
  "Background Inset": css``
}

const contentLayoutMixin = ({ $data: { sectionMediaSize, sectionMediaPosition, sectionMedia }}) =>
  sectionMediaSize && sectionMediaPosition && sectionMedia
  ? css`
    ${mediaPositions[sectionMediaPosition] || ""}
    ${mediaSizes[sectionMediaSize] || ""}
  ` : `
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 0;
  `

const contentAlignmentMixin = ({ $data: { sectionMedia, sectionMediaSize }}) => 
  sectionMediaSize && sectionMedia
    ? css`
      text-align: left;
    ` : css`
      text-align: center;
    `

const sectionBackgroundColorMixin = ({ theme, $data: { sectionBackgroundColor }}) => css`
  background-color: ${getColors(sectionBackgroundColor).primary || theme.colors.white};
`

const sectionBackgroundMediaMixin = ({ $data: { sectionMedia, sectionMediaSize }}) => 
  sectionMediaSize && sectionMedia && sectionMedia?.file?.url
    ? css`
      background: url(${sectionMedia?.file?.url});
    ` : ""

export default styled.div`
  ${sectionBackgroundColorMixin}
  ${sectionBackgroundMediaMixin}

  width: 100%;

  h1, h2, h3 h4, h5, h5, p {
    margin: 0;
  }

  p {
    font-family: ${props => props.theme.fonts.primary};
  }

  * {
    box-sizing: border-box;
  }

  .${props => props.primaryClass} {
    
    &__inner {
      display: flex;
      width: 100%;
      gap: 60px;
      padding: 5rem ${props => props.theme.padding.inner};
      margin: auto;
      min-height: none;
      max-width: 100rem;
      ${contentAlignmentMixin}
      ${contentLayoutMixin}

      @media (max-width: 1140px) {
        flex-direction: ${props => xPosIs("right")(props) ? 'column-reverse' : 'column'};
        justify-content: center;
        align-items: center;
      }
      
    }

    &__media {
      height: 100%;
      width: 100%;
      max-width: 684px;
      position: relative;

      &__thumbnail {
        position: relative;
        height: 100%;
        width: 100%;
        
        &__play-btn {
          position: absolute;
          top: 55%;
          left: 50%;
          z-index: 8;
          transform: translate(-50%, -50%);
          border: none;
          background: none;
          cursor: pointer;
        }
        
        
        picture {
          position: relative;
          z-index: 4;
          width: 100%;
          height: 100%;

          img {
            height: 100%;
            width: 100%;
          }
          
        }
        
      }

    }

    &__content {
      height: 100%;
      padding-left: 1.5rem;
      display: flex;
      flex-direction: column;
      align-items: ${props => props.$data?.alignText === "Center Align" ? "center" : props.$data?.alignText === "Right Align" ? "flex-end" : "flex-start"};
      text-align: ${props => props.$data?.alignText === "Center Align" ? "center" : props.$data?.alignText === "Right Align" ? "right" : "left"};

      @media (max-width: 1140px) {
        padding-left: 0;
        max-width: ${props => props.$data?.featuredImage?.file?.url ? 'calc(23rem * 1.7)' : '100%' };
      }


      &__label {
        color: ${props => props.labelColor};
        
        h1{
          color: ${props => props.labelColor};
        }
      }

      &__heading, &__heading * {
        color: ${props => props.headerColor};
        font-weight: bold;
        font-size: 2rem;
        margin-top: 0;
      }

      &__sub-heading, &__sub-heading * {
        color: ${props => props.subHeaderColor};
        font-weight: bold;
        font-size: 1.5rem;
        margin-top: 0;
      }

      &__disclaimer {
        padding-top: 1rem;
        color: ${props => props.disclaimerColor};
        
        a{
          color: ${props => props.disclaimerColor};
        }
        
      }

      &__intro {
        margin-top: 2rem;
        display: block;
        color: ${props => props.introColor};

        h1, h2, h3, h4, h5, h6, p {
          color: ${props => props.introColor};
        }

        p {
          margin-bottom: 0.75rem;
          line-height: normal;

          &:last-of-type {
            margin-bottom: 0;
          }
          
        }

        a {
          color: ${props => props.introColor};
          text-decoration: none;

          &:hover {
            transition: text-decoration 0.2s;
            text-decoration: underline;
          }
          
        }
        
      }

      &__links {
        margin-top: 2rem;
      }
      
    }
    
  }

`
