import styled, { css } from 'styled-components'
import { getColors } from 'vmfcom-lib/utils';

const getTextAlign = (alignContent) => {
  switch (alignContent) {
    case "Center Align":
      return 'center';
    case "Right Align":
      return 'right';
    case "Left Align":
    default:
      return 'left';

  }
}

// const generateFade = (topColor, bottomColor, fadeBlur = 10) => {
//   if (topColor && !bottomColor) {
//     return `linear-gradient(to bottom, ${topColor}, transparent ${fadeBlur}%)`
//   }

//   if (!topColor && bottomColor) {
//     return `linear-gradient(to top, ${bottomColor} transparent ${fadeBlur}%)`
//   }

//   if (topColor && bottomColor) {
//     return `linear-gradient(to bottom, ${topColor}, transparent ${fadeBlur}%, transparent ${100 - fadeBlur}%, ${bottomColor})`
//   }

//   return 'transparent'
// }

const narrowStyles = css`
  min-height: auto;
  .${props => props.primaryClass} {
    &__inner {
      padding: 0;
    }

    &__content {
      flex-direction: row;

      &__intro {
        margin: 0 0.5rem;
      }

      &__links {
        margin: 0;

        a {
          text-decoration: none;

          &:hover {
            color: white;
          }
          
        }
        
      }
      
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.tablet}) {
    .${props => props.primaryClass}__content {
      padding: 0.5rem 0;
      margin: 0;
      max-width: 210px;
      width: 100%;
      justify-content: flex-start;

      &__heading {
        display: none;
      }

      &__intro {
        text-align: start;
        margin: 0;
      }

    }
  }
`

export default styled.div`
  width: 100%;
  min-height: 30rem;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: ${props => getColors(props.$data.ctaBackgroundColor)?.primary || '#FFFFFF'};
  
  .cta {
    position: relative;
    z-index: 3;

    &__inner {
      max-width: ${props => props.theme.maxWidths.container};
      padding: ${props => props.theme.padding.inner};
      margin: auto;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
    }

    &__content {
      width: 100%;
      position: relative;
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 0 .5rem;
      padding: 1.5rem;
    }

    &__heading {
      font-family: 'futura-pt';
      text-align: ${props => getTextAlign(props.$data.alignCtaContent)};
      color: ${props =>  getColors(props.$data?.headerTextColor)?.primary ?? props.theme.colors.darkBlue};
    }

    &__intro {
      font-family: 'futura-pt';
      text-align: ${props => getTextAlign(props.$data.alignCtaContent)};

      * {
        color: ${props => getColors(props.$data?.introTextColor)?.primary ?? props.theme.colors.grey1};
      }

      p {
        margin: 0;
      }
    }

    &__links {
      display: flex;
      justify-content: center;
      margin-top: 1rem;
      align-items: center;
      
      a {
        font-size: 14px;
        font-family: 'nunito';
      }
      
      .link-text {
        display: flex;
        align-items: center;
        gap: .5rem;
      }
    }
  }

  .cta__background-image {
    position: absolute;
    opacity: ${props => props.inView ? 1 : 0};
    height: 100%;
    width: 100%;
    
    picture {
      position: relative;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 100%;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
      
    }
  }

  ${props => props.$data?.ctaSize === "Narrow" ? narrowStyles : ""}
  
`
